<template>
  <div>
    <confirm-modal />
    <info-modal
      v-if="!isInterview"
      :title="titleModal"
      :description="descriptionModal"
    />

    <div class="container-fluid dark interview">
      <comment-modal />
      <div v-if="recommendationsInvalid" class="recommendation-error">
        <span class="col-auto mx-3">
          ATENÇÃO! Você deve corrigir os erros indicados nas recomendações, caso
          contrário, elas <b>NÃO</b> serão salvas
        </span>
        <button
          class="btn btn-white-full col-auto"
          @click="goToRecommendations"
        >
          Ver recomendações
        </button>
      </div>
      <div class="container">
        <welcome />
        <div v-if="isInterview" id="futuro-presentation" class="mt-2 mb-5">
          <presentation :presentation="companyPresentationUrl" />
        </div>
        <customer-info
          ref="customerInfo"
          :is-interview="isInterview"
          interview-section="customerInfo"
          :is-detail="isDetail"
          :update-format-currency="updateFormatCurrency"
        />
        <!---->
        <goals
          ref="goals"
          :is-interview="isInterview"
          interview-section="goals"
          :is-detail="isDetail"
        />
        <monthly-investment
          ref="monthlyInvestment"
          :is-interview="isInterview"
          interview-section="monthlyInvestment"
          :is-detail="isDetail"
        />
        <acquisition
          ref="acquisition"
          :is-interview="isInterview"
          interview-section="acquisition"
          :is-detail="isDetail"
        />
        <patrimony
          ref="patrimony"
          :is-interview="isInterview"
          interview-section="patrimony"
          :is-detail="isDetail"
        />
        <life-insurance
          ref="lifeInsurance"
          :is-interview="isInterview"
          interview-section="lifeInsurance"
          :is-detail="isDetail"
          :update-cash-flow="updateCashFlow"
        />
        <professional-insurance
          ref="professionalInsurance"
          :is-interview="isInterview"
          interview-section="professionalInsurance"
          :is-detail="isDetail"
          :update-cash-flow="updateCashFlow"
        />
        <health-insurance
          ref="healthInsurance"
          :is-interview="isInterview"
          interview-section="healthInsurance"
          :is-detail="isDetail"
        />
        <debts
          ref="debts"
          :is-interview="isInterview"
          interview-section="debts"
          :is-detail="isDetail"
        />
        <retirement
          ref="retirement"
          :is-interview="isInterview"
          interview-section="retirement"
          :is-detail="isDetail"
          @add-saving-subcategory="addSavingSubcategory"
          @remove-saving-subcategory="removeSubCategoryData"
        />
        <investment
          v-if="isInterview"
          ref="investment"
          :is-interview="isInterview"
          interview-section="investment"
          :is-detail="isDetail"
          :update-cash-flow="updateCashFlow"
        />
        <interest-in-living-abroad
          v-if="isInterview"
          ref="interestInLivingAbroad"
          :is-interview="isInterview"
          interview-section="interestInLivingAbroad"
          :is-detail="isDetail"
        />
        <investment-trimmed
          v-if="!isInterview"
          ref="investmentTrimmed"
          :is-interview="isInterview"
          interview-section="investmentTrimmed"
          :is-detail="isDetail"
        />
        <financial-information
          ref="financialInformation"
          :is-interview="isInterview"
          interview-section="financialInformation"
          :is-detail="isDetail"
        />
        <patrimony-sum
          ref="patrimonySum"
          :is-interview="isInterview"
          interview-section="patrimonySum"
        />
        <additional-information
          ref="additionalInformation"
          :is-interview="isInterview"
          interview-section="additionalInformation"
          :is-detail="isDetail"
        />
        <template v-if="!isCustomer">
          <cash-flow
            ref="cashFlow"
            :is-interview="isInterview"
            interview-section="cashFlow"
            :is-detail="isDetail"
          />
        </template>
        <div v-if="isInterview" class="my-5">
          <presentation :presentation="methodPresentationUrl" />
        </div>
        <financial-diagnosis
          v-if="isInterview"
          :is-detail="isDetail"
          :isLoading="isLoading"
        />
        <template v-if="isInterview && !isCustomer">
          <value-per-month
            :is-interview="isInterview"
            interview-section="valuePerMonth"
            ref="valuePerMonth"
          />
          <proposal
            ref="proposal"
            :is-interview="isInterview"
            interview-section="proposal"
            :is-detail="isDetail"
          />
          <transactions-configuration
            ref="transactionsConfigurations"
            :is-interview="isInterview"
            interview-section="transactionsConfigurations"
            :is-detail="isDetail"
          />
        </template>
        <template v-if="isInterview && !isDetail && !isCustomer">
          <div class="card">
            <div class="row align-items-center justify-content-between mb-3">
              <div class="card-title col-auto">
                <img
                  src="~@/assets/img/ic-contract.svg"
                  alt="Futuro | Consultoria Financeira"
                />
                <h2>Contrato</h2>
              </div>
              <template v-if="!isDetail">
                <button
                  v-if="contracts.length > 0"
                  :disabled="isLoading"
                  class="btn btn-orange col-auto mx-1"
                  @click.prevent="submit(true)"
                >
                  {{
                    isLoading
                      ? "Por favor, aguarde um momento..."
                      : "Gerar contrato"
                  }}
                </button>
              </template>
            </div>
            <template v-if="contracts.length > 0">
              <div class="row mb-3 gy-3">
                <p class="mb-1">
                  <b>Parabéns</b>, você está a apenas dois passos de planejar o
                  seu Futuro financeiro!
                </p>
                <ol>
                  <li>
                    Para firmarmos a nossa parceria, enviamos para o seu e-mail
                    o
                    <b>contrato de prestação de serviço</b> que deve ser
                    assinado.
                  </li>
                  <li>
                    Encaminharemos também o
                    <b>link para efetuar o pagamento</b>, através de uma das
                    opções abaixo:
                  </li>
                </ol>
              </div>
              <div class="row justify-content-center g-3">
                <button
                  class="btn btn-outline-light col-auto"
                  @click.prevent="copyPaymentLinkToClipboard"
                >
                  Copiar link de pagamento
                </button>
                <button
                  class="btn btn-whatsapp col-auto ms-2"
                  @click.prevent="openWhatsappPaymentLink"
                >
                  <img
                    src="~@/assets/img/ic-whatsapp-green.svg"
                    alt="Compartilhar link por whatsapp"
                  />
                  Compartilhar link de pagamento por whatsapp
                </button>
              </div>
            </template>
            <template v-else>
              <div class="row">
                <p class="mb-1">
                  <b>Último passo!</b> Agora, basta gerar o contrato e concluir
                  a negociação realizando a assinatura e pagamento.
                </p>
              </div>
              <div class="row mb-3 align-items-center justify-content-end">
                <div class="col-auto">
                  <template v-if="!isDetail">
                    <button
                      :disabled="isLoading"
                      class="btn btn-orange"
                      @click.prevent="submit(true)"
                    >
                      {{
                        isLoading
                          ? "Por favor, aguarde um momento..."
                          : "Gerar contrato"
                      }}
                    </button>
                  </template>
                </div>
              </div>
            </template>
          </div>
          <commercial-proposal v-if="!isDetail" :isLoading="isLoading" />
        </template>

        <section
          v-if="!isInterview"
          class="finish-section"
          :class="isAtEndOfPage ? 'finish-section end-page' : 'finish-section'"
        >
          <div class="finish-container">
            <div
              class="container container-info d-flex align-items-center h-100 justify-content-between"
            >
              <div
                class="info d-flex align-items-center justify-content-between h-100"
              >
                <div class="img h-100">
                  <img
                    src="~@/assets/img/male-avatar-info.svg"
                    alt="male-avatar"
                    class="h-100"
                    loading="lazy"
                  />
                </div>
                <div class="ready-start mx-3">
                  <p class="m-0">
                    Parabéns por dar o primeiro passo na sua vida financeira!
                    Para prosseguir, basta rolar até o final da página, e o
                    botão 'Finalizar' estará habilitado para você
                  </p>
                </div>
                <div class="complete-interview d-lg-none text-center">
                  <p class="m-0">
                    Complete o cadastro para liberar o botão de finalizar.
                  </p>
                </div>
              </div>
              <div
                class="finish-buttons d-flex justify-content-center align-items-center"
              >
                <div v-if="isLoading" style="margin-right: 0.25rem">
                  <spinner />
                </div>
                <button
                  class="btn btn-save"
                  :disabled="isLoading"
                  @click="saveToFinishLater()"
                />
                <button
                  class="btn btn-finish"
                  :disabled="isLoading || !isAtEndOfPage"
                  @click.prevent="openConfimationModal()"
                >
                  Finalizar
                </button>
              </div>
            </div>
          </div>

          <!-- <app-footer /> -->
        </section>
        <div v-if="!isInterview" class="give-space-before-finalizing" />
      </div>
    </div>
    <data-loading v-if="isLoading" :isLoading="isLoading" />
    <fab-menu v-if="isInterview" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { processFormErrors } from "@/helpers/interview_payloads";
import { processErrorMessages } from "@/helpers/errors";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import * as accountTypes from "../../account/store/types";
import Spinner from "@/modules/shared/components/Spinner";
import ConfirmModal from "@/modules/shared/components/ConfirmModal";
import Welcome from "../components/Welcome";
import Presentation from "@/modules/shared/components/Presentation";
import CommentModal from "../components/CommentModal";
import InfoModal from "../components/InfoModal";
import CustomerInfo from "../components/CustomerInfo";
import Goals from "../components/Goals";
import MonthlyInvestment from "../components/MonthlyInvestment";
import Acquisition from "../components/Acquisition";
import Patrimony from "../components/Patrimony";
import LifeInsurance from "../components/LifeInsurance";
import ProfessionalInsurance from "../components/ProfessionalInsurance";
import HealthInsurance from "../components/HealthInsurance";
import Debts from "../components/Debts";
import Retirement from "../components/Retirement";
import Investment from "../components/Investment";
import InterestInLivingAbroad from "../components/InterestInLivingAbroad";
import InvestmentTrimmed from "../components/InvestmentTrimmed";
import CashFlow from "../components/CashFlow";
import ValuePerMonth from "../components/ValuePerMonth";
import PatrimonySum from "../components/PatrimonySum";
import Proposal from "../components/Proposal";
import AdditionalInformation from "../components/AdditionalInformation";
import FinancialInformation from "../components/FinancialInformation";
import axios from "axios";
import FabMenu from "../components/FabMenu.vue";
import DataLoading from "../components/DataLoading.vue";
import AppFooter from "../../main/components/AppFooter.vue";
import CommercialProposal from "../components/CommercialProposal";
import TransactionsConfiguration from "../components/TransactionsConfiguration";
import FinancialDiagnosis from "../components/FinancialDiagnosis";

export default {
  components: {
    Welcome,
    Presentation,
    CommentModal,
    CustomerInfo,
    Goals,
    MonthlyInvestment,
    Acquisition,
    Patrimony,
    LifeInsurance,
    ProfessionalInsurance,
    HealthInsurance,
    Debts,
    Retirement,
    Investment,
    InterestInLivingAbroad,
    InvestmentTrimmed,
    CashFlow,
    ValuePerMonth,
    PatrimonySum,
    Proposal,
    AdditionalInformation,
    FinancialInformation,
    Spinner,
    FabMenu,
    ConfirmModal,
    AppFooter,
    InfoModal,
    CommercialProposal,
    TransactionsConfiguration,
    FinancialDiagnosis,
    DataLoading,
  },
  props: {
    isInterview: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const v = useVuelidate();
    return { v };
  },
  data() {
    return {
      isAtEndOfPage: false,
      titleModal: "Pronto para começar?",
      descriptionModal:
        "Desça a página, preencha todos os campos relevantes relacionados à sua vida financeira e, ao rolar até o fim da página, o botão 'Finalizar' será ativado.",
    };
  },
  computed: {
    ...mapGetters({
      customerInfo: types.CUSTOMER_INFO,
      processing: types.SAVING_INTERVIEW,
      contracts: types.CUSTOMER_CONTRACTS,
      cashFlowLoading: types.INTERVIEW_CASH_FLOW_LOADING,
      proposalLoading: types.INTERVIEW_PROPOSAL_LOADING,
    }),
    companyPresentationUrl: function () {
      return process.env.VUE_APP_COMPANY_CANVA_PRESENTATION;
    },
    methodPresentationUrl: function () {
      return process.env.VUE_APP_METHOD_CANVA_PRESENTATION;
    },
    isLoading: function () {
      return this.processing || this.cashFlowLoading || this.proposalLoading;
    },
    recommendationsInvalid: function () {
      const proposalForm = this.v.$getResultsForChild("proposal");
      let invalid = false;
      if (proposalForm?.$errors?.length) {
        return proposalForm.$errors.some(
          (error) => error.$property === "recommendations"
        );
      }
      return invalid;
    },
  },
  mounted() {
    if (this.isInterview) {
      this.getUserData();
    }
    this.validatePreInterview();
    this.initInterviewForm(this.v);
    this.setInterviewHeaderType();
    window.addEventListener("scroll", this.checkScrollPosition);
    this.showInfoModal();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkScrollPosition);
  },
  methods: {
    ...mapActions({
      setInterviewHeaderType: sharedTypes.SET_INTERVIEW_HEADER_TYPE,
      generateContract: types.GENERATE_CONTRACT,
      getUserData: accountTypes.GET_USER_DATA,
    }),
    ...mapMutations({
      savingInterviewState: types.MUTATE_SAVING_INTERVIEW,
      initInterviewForm: types.MUTATE_INTERVIEW_FORM,
      setCustomerContracts: types.MUTATE_CUSTOMER_CONTRACTS,
    }),
    showInfoModal() {
      this.$vfm.show("interview-info", {});
    },
    saveToFinishLater() {
      this.$vfm.show("message", {
        type: "success",
        messages: ["Sua pré-entrevista foi salva!"],
      });
    },
    async submit() {
      const errors = await processFormErrors(this.v);
      if (errors.length > 0) {
        const messages = [
          "O contrato não foi gerado.",
          "Você deve solucionar as inconsistências da entrevista apontadas nas seguintes seções:",
          ...errors,
        ];
        this.$vfm.show("message", {
          type: "error",
          messages,
        });
      } else {
        this.savingInterviewState(true);
        this.generateContract()
          .then((response) => {
            this.setCustomerContracts([{ id: 0 }]);
            this.$vfm.show("message", {
              type: "success",
              messages: ["Contrato gerado com sucesso!"],
            });
          })
          .catch((error) => {
            const errorsFromPayload = processErrorMessages(
              error?.response?.data
            );
            const messages = [
              "Não foi possível gerar o contrato.",
              ...errorsFromPayload,
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ];
            this.$vfm.show("message", {
              type: "error",
              messages,
            });
          })
          .finally(() => {
            this.savingInterviewState(false);
          });
      }
    },
    addSavingSubcategory(data) {
      this.$refs.cashFlow.addSubCategoryData(data);
    },
    removeSubCategoryData(data) {
      this.$refs.cashFlow.removeSubCategoryData(data);
    },
    openWhatsappPaymentLink() {
      const { token, name, cell_phone } = this.customerInfo;
      const firstName = name.split(" ")[0];
      const link = `${process.env.VUE_APP_URL}/investimento/${token}`;
      const message = `${firstName}, você poderá efetuar o pagamento do projeto financeiro Futuro através do link: ${link}`;
      setTimeout(() => {
        window.open(
          `https://api.whatsapp.com/send?phone=${cell_phone}&text=${message}`,
          "_blank"
        );
      });
    },
    copyPaymentLinkToClipboard() {
      const link = `${process.env.VUE_APP_URL}/investimento/${this.customerInfo.token}`;
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
      clipboardData.writeText(link);
      this.$vfm.show("message", {
        type: "success",
        messages: [
          "O link de pagamento foi copiado para a área de transferência!",
        ],
      });
    },
    async savePreInverview() {
      try {
        this.savingInterviewState(true);

        await axios.get(
          `/api/interviews/${this.$route.params.customerId}/interview/finish_pre_interview`
        );
        // Redirect to successful completion
        this.$router.push("/pre-entrevista/finalizada");
      } catch (err) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível salvar suas informações.",
            "Entre em contato com o seu consultor.",
          ],
        });
      } finally {
        this.savingInterviewState(false);
      }
    },
    validatePreInterview() {
      if (!this.isInterview) {
        this.savingInterviewState(true);
        return axios
          .get(`/api/interviews/${this.$route.params.customerId}/interview`)
          .then((resp) => {
            if (resp.data.is_interview) {
              //redireciona para finalizado com sucesso
              this.$router.push("/pre-entrevista/finalizada");
            }
          })
          .catch((err) => {
            this.$vfm.show("message", {
              type: "error",
              messages: [
                "Não foi possível salvar suas informações.",
                "Entre em contato com o seu consultor.",
              ],
            });
          })
          .finally(() => {
            this.savingInterviewState(false);
          });
      }
    },
    goToRecommendations() {
      const element = this.$el.querySelector("#recommendationsSection");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    checkScrollPosition() {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.scrollY + 15;

      if (scrollPosition + windowHeight >= documentHeight) {
        this.isAtEndOfPage = true;
      } else {
        this.isAtEndOfPage = false;
      }
    },
    openConfimationModal() {
      this.$vfm.show(
        {
          component: ConfirmModal,
        },
        {
          messages: ["Deseja realmente finalizar a pré-entrevista?"],
          handleConfirm: () => this.savePreInverview(),
        }
      );
    },
    updateFormatCurrency() {
      this.$refs.additionalInformation.fetchAll();
      this.$refs.cashFlow.fetchAll();
      this.$refs.customerInfo.fetchAll();
      this.$refs.debts.fetchAll();
      this.$refs.financialInformation.fetchAll();
      this.$refs.investment.fetchAll();
      this.$refs.monthlyInvestment.fetchAll();
      this.$refs.patrimonySum.fetchAll();
      this.$refs.proposal.fetchAll();
      this.$refs.transactionsConfigurations.fetchAll();
    },
    updateCashFlow() {
      this.$refs.cashFlow.fetchAll();
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  img {
    margin-right: 10px;
  }
}
.dark {
  &.interview {
    background-image: url("https://futurogfp-assets.s3.sa-east-1.amazonaws.com/bg-interview.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-color: var(--blue-bg);
  }
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .dark {
    &.interview {
      background: unset;
      background-color: var(--blue-bg);
    }
  }
}
.recommendation-error {
  background: #dc3545;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  width: 98%;
  position: fixed;
  z-index: 100;
  bottom: 1rem;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.finish-interview {
  width: max-content;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 50px;

  button {
    font-weight: 700 !important;
  }
}

.font-loading {
  font-size: 0.9rem;
}

.font-done {
  font-size: 1rem;
}

.finish-section {
  width: 100%;
  position: fixed;
  bottom: -48px;
  left: 50%;
  transform: translate(-50%, -50%);
  .finish-container {
    width: 100%;
    height: 95px;
    background-color: #0232a0;

    @media (max-width: 991.98px) {
      .img,
      .ready-start {
        display: none;
      }

      .container-info {
        flex-direction: column;
      }

      & {
        padding-bottom: 0.75rem;
      }
    }
  }

  .finish-buttons {
    margin-left: 1rem;
    @media (max-width: 767.98px) {
      margin-left: 0;
      // flex-direction: column;
    }
    button {
      min-width: 221px;
      padding: 0.5rem 0.75rem;
      color: #fff;
      font-weight: bold !important;
      font-size: 1rem;
      border-radius: 7px;
      letter-spacing: auto;

      @media (max-width: 767.98px) {
        min-width: 150px;
      }
    }

    .btn-save {
      background-color: #f6700d;
      margin-right: 1rem;

      &::after {
        content: "Salvar e finalizar depois";
      }

      @media (max-width: 767.98px) {
        &::after {
          content: "Finalizar depois";
        }
      }
    }
    .btn-finish {
      background-color: #43c908;
    }
  }
}

.end-page {
  bottom: -18px !important;
}

.give-space-before-finalizing {
  margin-top: 5rem !important;
}
</style>

<template>
  <div class="container-fluid h-100 dark data-loading">
    <div
      class="d-flex flex-column h-100 align-items-center justify-content-center"
    >
      <img
        src="~@/assets/img/logo-white.svg"
        height="150"
        alt="Futuro | Consultoria Financeira"
      />
      <span class="mt-4">Seus dados estão sendo carregados</span>
      <span>Aguarde um momento...</span>

      <spinner class="mt-3" size="large" />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapMutations, mapActions } from "vuex";
import * as types from "../store/types";
import { processFormErrors } from "@/helpers/interview_payloads";
import { processErrorMessages } from "@/helpers/errors";
import Spinner from "@/modules/shared/components/Spinner";

export default {
  components: {
    Spinner,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.data-loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
}
</style>
